import { Component, OnInit } from '@angular/core';
import { interval } from 'rxjs';
import { MockedIotBackendService } from '../mocked-iot-backend.service';

@Component({
    selector: 'app-simulation-control',
    templateUrl: './simulation-control.component.html',
    styleUrls: ['./simulation-control.component.scss'],
})
export class SimulationControlComponent implements OnInit {
    constructor(private mockedBackend: MockedIotBackendService) {}

    ngOnInit(): void {
        interval(1500).subscribe(() => {
            const getSection = (direction: 'delivered' | 'received') =>
                Object.fromEntries(
                    this.simulationState
                        .filter((v) => v.direction === direction && v.enabled)
                        .map((v) => [v.name, v.value]),
                );
            this.mockedBackend.events$.next([
                this.mockedBackend.generateEmsPowerBalance({
                    received: getSection('received'),
                    delivered: getSection('delivered'),
                }),
                this.mockedBackend.generateEmsKpi(this.statistics.autarky, this.statistics.selfConsumption),
                this.mockedBackend.generateStateOfCharge(this.statistics.batteryCharge),
            ]);
            this.mockedBackend.maximumAnalyticsValue = this.statistics.maximumAnalyticsValue;
            this.mockedBackend.hideFeature = this.statistics.hideFeature;
        });
    }

    public simulationState = [
        ...['Photovoltaic', 'Grid', 'Accumulator'].map((name) => ({
            name,
            value: 100,
            enabled: true,
            direction: 'delivered',
        })),
        ...['Heatpump', 'Household', 'Accumulator', 'Grid', 'VehicleChargingStation', 'PhotovoltaicsHeater'].map(
            (name) => ({
                name,
                value: 100,
                enabled: true,
                direction: 'received',
            }),
        ),
    ];

    public statistics = {
        autarky: 100,
        selfConsumption: 90,
        batteryCharge: 85,
        maximumAnalyticsValue: 40,
        hideFeature: '',
    };

    trackByItem(_: number, item: { key: string; value: { enabled: boolean; value: number } }) {
        return item.key;
    }
}
