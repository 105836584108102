export type IotFeatureBase = {
    commands: {};
    apiVersion: string | number;
    uri: string;
    gatewayId: string;
    timestamp: string;
    isEnabled: boolean;
    isReady: boolean;
    deviceId: string;
};

export interface EmsKpiFeature extends IotFeatureBase {
    properties: {
        calculationStatus: {
            type: 'string';
            value: string;
        };
        autarky: {
            type: 'number';
            value: number;
            unit: string;
        };
        selfConsumption: {
            type: 'number';
            value: number;
            unit: string;
        };
    };
    feature: 'ems.kpi';
}

export type EmsPowerBalanceReceivedType =
    | 'Heatpump'
    | 'Household'
    | 'Accumulator'
    | 'Grid'
    | 'VehicleChargingStation'
    | 'PhotovoltaicsHeater';
export type EmsPowerBalanceDeliveredType = 'Photovoltaic' | 'Grid' | 'Accumulator';
export interface EmsPowerBalanceFeature extends IotFeatureBase {
    properties: {
        calculationStatus: {
            type: 'string';
            value: string;
        };
        received: {
            type: 'array';
            value: ReceivedFeature[];
        };
        delivered: {
            type: 'array';
            value: DeliveredFeature[];
        };
        totalConsumption: {
            type: string;
            value: number;
            unit: string;
        };
        totalProduction: {
            type: string;
            value: number;
            unit: string;
        };
    };
    feature: 'ems.power.balance';
}

export interface ReceivedFeature {
    value: number;
    unit: string;
    type: EmsPowerBalanceReceivedType;
    components?: FeatureComponent[];
}

export interface DeliveredFeature {
    value: number;
    unit: string;
    type: EmsPowerBalanceDeliveredType;
    components?: FeatureComponent[];
}

export interface FeatureComponent {
    value: number;
    unit: string;
    type: string;
}

export interface EmsStateOfChargeFeature extends IotFeatureBase {
    properties: {
        value: {
            type: string;
            value: number;
            unit: string;
        };
    };
    feature: 'ess.stateOfCharge';
}
export type IotFeature = EmsKpiFeature | EmsPowerBalanceFeature | EmsStateOfChargeFeature;

export function getFeature<
    T extends IotFeature['feature'],
    Z extends Extract<IotFeature, { feature: T }>['properties'],
>(name: T, features: IotFeature[]): Z {
    const found = features.find((feature) => feature.feature === name);
    if (!found) {
        throw new Error(`Feature not found ${name}`);
    }
    return <Z>found.properties;
}

export function getOptionalFeature<
    T extends IotFeature['feature'],
    Z extends Extract<IotFeature, { feature: T }>['properties'],
>(name: T, features: IotFeature[]): Z | undefined {
    const found = features.find((feature) => feature.feature === name);
    if (!found) {
        return;
    }
    return <Z>found.properties;
}
