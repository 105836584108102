import { Injectable } from '@angular/core';
import { combineLatest, map, Observable, of, scan, startWith, switchMap } from 'rxjs';
import { IconsService } from './icons.service';
import { HttpIotBackendService } from './iot-backend/http-iot-backend.service';
import {
    FeatureSubscription,
    InstallationOverviewResponse,
    IotBackendService,
    vNextDevice,
} from './iot-backend/iot-backend.service';
import { DeliveredFeature, getFeature, getOptionalFeature, IotFeature, ReceivedFeature } from './IotFeatures';

@Injectable({
    providedIn: 'root',
    deps: [],
})
export class DataService {
    constructor(
        private iotBackendService: HttpIotBackendService,
        private iconsService: IconsService,
    ) {}

    loadData(
        installationId: string,
        iotBackendService: IotBackendService = this.iotBackendService,
    ): Observable<EnergyCockpitData> {
        return iotBackendService.getInstallation(installationId).pipe(
            switchMap((response) => {
                const hemsDevice = this.findHemsDevice(response);
                const gatewayId = hemsDevice.gatewaySerial;
                const batteryDevice = this.findBatteryDevice(response, gatewayId);

                return combineLatest({
                    devices: of(<DeviceIds>{ gatewayId, batteryDeviceId: batteryDevice?.id }),
                    features: this.setupFeaturesAndLiveUpdatesSubscriptions({
                        iotBackendService,
                        installationId,
                        hemsDevice,
                        batteryDevice,
                    }),
                });
            }),
            map(({ devices, features }) => {
                const kpiFeature = getFeature('ems.kpi', features);
                const batteryChargeFeature = getOptionalFeature('ess.stateOfCharge', features);

                return {
                    tableData: this.getTableData(features),
                    chartsKpi: {
                        [ConsumptionType.AUTARKY]: Math.round(kpiFeature?.autarky?.value || 0),
                        [ConsumptionType.SELF_CONSUMPTION]: Math.round(kpiFeature?.selfConsumption?.value || 0),
                    },
                    batteryCharge: batteryChargeFeature?.value?.value,
                    devices,
                };
            }),
        );
    }

    private getTableData(features: IotFeature[]): EnergyTableItem[] {
        const emsPowerBalance = getFeature('ems.power.balance', features);

        return Object.entries({
            [EnergyTableItemCategory.DELIVERED]: emsPowerBalance.delivered.value,
            [EnergyTableItemCategory.RECEIVED]: emsPowerBalance.received.value,
        }).flatMap(([type, values]) =>
            values.map(
                (v) =>
                    <EnergyTableItem>{
                        icon: this.iconsService.getTableItemIcon(<EnergyTableItemCategory>type, v.type),
                        type: v.type,
                        value: v.value,
                        unit: v.unit,
                        category: <EnergyTableItemCategory>type,
                        additionalInfo: this.buildAdditionalInfo(v),
                    },
            ),
        );
    }

    private findHemsDevice(response: InstallationOverviewResponse): vNextDevice {
        const hemsDevices = response.data.gateways
            .map((gateway) => gateway.devices.find((device) => device.id === 'HEMS'))
            .filter((v) => !!v);
        if (!hemsDevices || hemsDevices.length === 0) {
            throw new Error('HEMS not supported');
        }
        return <vNextDevice>hemsDevices.at(0);
    }

    private findBatteryDevice(response: InstallationOverviewResponse, gatewayId: string): vNextDevice | undefined {
        return response.data.gateways
            .find((gateway) => gateway.serial === gatewayId)
            ?.devices.find((device) => device.roles.includes('type:ess'));
    }

    private mergeFeatures(featureSources: IotFeature[][]): IotFeature[] {
        let features: IotFeature[] = [];
        for (const featureChunk of featureSources) {
            features = [
                ...features.filter(
                    (existingFeature) => !featureChunk.some((feature) => feature.feature === existingFeature.feature),
                ),
                ...featureChunk,
            ];
        }
        return features;
    }

    private setupFeaturesAndLiveUpdatesSubscriptions({
        iotBackendService,
        installationId,
        hemsDevice,
        batteryDevice,
    }: {
        iotBackendService: IotBackendService;
        installationId: string;
        hemsDevice: vNextDevice;
        batteryDevice?: vNextDevice;
    }) {
        const gatewayId = hemsDevice.gatewaySerial;
        const subscriptions: FeatureSubscription[] = [];

        subscriptions.push({
            deviceId: hemsDevice.id,
            gatewayId,
            installationId,
            features: ['ems.power.balance', 'ems.kpi'],
        });

        if (batteryDevice) {
            subscriptions.push({
                deviceId: batteryDevice.id,
                gatewayId,
                installationId,
                features: ['ess.stateOfCharge'],
            });
        }

        const liveUpdatesSubscription = iotBackendService
            .subscribeToDeviceFeaturesUpdates(subscriptions)
            .pipe(scan<IotFeature[], IotFeature[]>((previous, current) => this.mergeFeatures([previous, current]), []));

        return combineLatest([
            ...subscriptions.map((subscription) => iotBackendService.getDeviceFeatures(subscription)),
            liveUpdatesSubscription.pipe(startWith([])),
        ]).pipe(map(this.mergeFeatures));
    }

    private buildAdditionalInfo(v: ReceivedFeature | DeliveredFeature): string[] {
        const additionalInfo = [];
        if (v.type === 'Accumulator') {
            additionalInfo.push('isBattery');
        }
        if (v.components?.find((component) => component.type === 'ElectricalHeater')) {
            additionalInfo.push('hasElectricalHeater');
        }
        return additionalInfo;
    }
}

export interface EnergyCockpitData {
    tableData: EnergyTableItem[];
    chartsKpi: {
        [ConsumptionType.AUTARKY]: number;
        [ConsumptionType.SELF_CONSUMPTION]: number;
    };
    batteryCharge?: number;
    devices: DeviceIds;
}
export interface ValueItemWithUnit {
    value: number;
    unit: string;
}

export interface EnergyTableItem extends ValueItemWithUnit {
    icon: string;
    type: string;
    category: EnergyTableItemCategory;
    additionalInfo: string[];
}

export enum EnergyTableItemCategory {
    DELIVERED = 'delivered',
    RECEIVED = 'received',
}

export enum ConsumptionType {
    AUTARKY = 'autarky',
    SELF_CONSUMPTION = 'self_consumption',
}

export interface DeviceIds {
    gatewayId: string;
    batteryDeviceId?: string;
}
